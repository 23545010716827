import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { StatusCodes } from 'http-status-codes';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { connectionService, invitationService } from '../../../services';
import { CommunityRequest, InviteType } from '../../../types/invites';
import { ProfileField } from '../../../types/profile';
import { InvalidAccountResponse } from '../../../types/responses';
import ResponsePageWrapper from '../../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../../components/headers/InviteHeader';
import InvalidAuthHeader from '../../../components/headers/InvalidAuthHeader';
import InvalidAuthBody from '../../../components/misc/InvalidAuthBody';
import UserDataForm from '../../../components/forms/userData/UserDataForm';
import { SuccessPageState } from '../../../types/navigation';
import NoDataSharedModal from '../../../components/modals/NoDataSharedModal';
import Button from '../../../components/misc/Button';
import useMagicLogin from '../../../hooks/useMagicLogin';

export default function CommunityRequestPage(): JSX.Element {
  const [params] = useSearchParams();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (useMagicLogin()) {
    return <></>;
  }

  const invitationId = params.get('invitationId');

  const [invalidAccountInfo, setInvalidAccountInfo] = useState<InvalidAccountResponse>();
  const [communityRequest, setCommunityRequest] = useState<CommunityRequest>(location.state?.request ?? undefined);
  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [noDataSharedModalOpen, setNoDataSharedModalOpen] = useState(false);

  useEffect(() => {
    if (!invitationId && !location.state?.request) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
      return;
    }
    if (location.state?.request) return;

    invitationService
      .getCommunityRequest(invitationId!)
      .then((req) => {
        setCommunityRequest(req);
        setInvalidAccountInfo(undefined);
      })
      .catch((err: AxiosError<InvalidAccountResponse>) => {
        if (err?.response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          setInvalidAccountInfo(err.response.data);
        } else {
          toast.error(t('toast.error.general.invalidInvite'));
          navigate('/');
        }
      });
  }, [JSON.stringify(auth)]);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setNoDataSharedModalOpen(true);
    else acceptRequest();
  };

  const acceptRequest = async () => {
    if (!communityRequest) return;
    await connectionService.acceptCommunityRequest(
      communityRequest?.userId!,
      communityRequest?.communityId!,
      selectedData.map((x) => +x.id!),
    );
    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.communityRequest.accepted',
      translationOptions: {
        requesterAlias: communityRequest.alias.replace('+', ' '),
        communityName: communityRequest.communityName,
        requesterName: communityRequest.alias.split('+')[0],
      },
      requesterId: `${communityRequest?.userId!}`,
      requesterAlias: communityRequest.alias,
    };

    toast.success('success');

    navigate('/success', { state });
  };

  const declineRequest = async () => {
    if (!communityRequest) return;
    await connectionService.declineCommunityRequest(communityRequest.userId);

    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.communityRequest.rejected',
      translationOptions: {
        requesterAlias: communityRequest.alias.replace('+', ' '),
      },
    };
    toast.success('success');
    navigate('/success', { state });
  };

  if (invalidAccountInfo)
    return (
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InvalidAuthHeader type={InviteType.COMMUNITY_REQUEST} />
        </ResponsePageWrapper.Header>
        <InvalidAuthBody
          info={invalidAccountInfo}
          invitationId={invitationId!}
          inviteType={InviteType.COMMUNITY_REQUEST}
        />
      </ResponsePageWrapper>
    );

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InviteHeader
            requesterName={communityRequest?.alias.replace('+', ' ') || ''}
            submessage={t('header.invalidAuth.message')}
          />
        </ResponsePageWrapper.Header>
        <div className="mb-4 flex flex-col">
          <h2 className="text-lg font-medium">{t('page.communityRequest.title')}</h2>
          <p className="text-sm">
            {t('page.communityRequest.subtitle', {
              name: communityRequest?.alias.replace('+', ' '),
              communityName: communityRequest?.communityName,
            })}
          </p>
        </div>
        <UserDataForm selectedData={selectedData || []} setSelectedData={setSelectedData} hasSelectAll />
        <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button className="underline" onClick={declineRequest}>
            {t('general.decline')}
          </Button>
          <Button variant="primary" className="px-[32px]" onClick={handleComplete}>
            {t('page.shared.shareData')}
          </Button>
        </div>
      </ResponsePageWrapper>
      <NoDataSharedModal
        open={noDataSharedModalOpen}
        setOpen={setNoDataSharedModalOpen}
        requesterName={communityRequest?.alias.split('+')[0] || ''}
        handleComplete={acceptRequest}
      />
    </>
  );
}
