import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import LoginButton from '../../components/misc/LoginButton';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import { detectMobileOS } from '../../utils/userAgentUtils';
import { MobileOS } from '../../types/misc';

export default function MagicLoginPage() {
  const { t } = useTranslation();

  const [params] = useSearchParams();
  const redirectTo = params.get('redirectTo') ?? '/';
  const mobileOS = detectMobileOS();

  return (
    <ResponsePageWrapper>
      <ResponsePageWrapper.Header>
        <h1 className="font-serif text-2xl font-medium text-primary-900">
          {mobileOS === MobileOS.UNKNOWN ? t('page.magicLogin.continue') : t('page.magicLogin.mobile')}
        </h1>
      </ResponsePageWrapper.Header>
      <LoginButton redirectTo={redirectTo} />
    </ResponsePageWrapper>
  );
}
