import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Invite, InviteType } from '../../types/invites';
import { connectionService, invitationService, profileService } from '../../services';
import { ProfileDataType, ProfileField } from '../../types/profile';
import { createEmptyField } from '../../utils/formatUtils';
import ProfileFieldForm from '../../components/forms/profile/ProfileFieldForm';
import { sortProfileFields } from '../../utils/sortUtils';
import { isEmptyField, isValidProfileFields } from '../../utils/validationUtils';
import { Language } from '../../types/requests';
import { LoginPageState, SuccessPageState } from '../../types/navigation';
import NameInput from '../../components/forms/profile/NameInput';
import { joinWithAnd, profileDataTypeToString } from '../../utils/stringUtils';
import TermsAndConditions from '../../components/misc/TermsAndConditions';
import EmailInput from '../../components/forms/profile/EmailInput';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../components/headers/InviteHeader';
import BusinessCardHeader from '../../components/headers/BusinessCardHeader';
import Button from '../../components/misc/Button';
import AccountExistsModal from '../../components/modals/AccountExistsModal';
import useMagicLogin from '../../hooks/useMagicLogin';

export default function UnauthenticatedInvitePage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  if (useMagicLogin()) {
    return <></>;
  }

  const invitationId = params.get('invitationId');
  const link = params.get('link');

  const [invite, setInvite] = useState<Invite>();
  const [alias, setAlias] = useState<string>('');
  const [profileFields, setProfileFields] = useState<ProfileField[]>([]);
  const [accountExists, setAccountExists] = useState<boolean>(false);
  const [accountExistsModal, setAccountExistsModal] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);

  const emailField = profileFields.find((x) => x.dataType === ProfileDataType.EMAIL);

  useEffect(() => {
    if (!invitationId) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
    }
  }, []);

  useLayoutEffect(() => {
    if (!invitationId || link) return;

    const setFields = async () => {
      const inv =
        (location.state?.invite as Invite) ||
        (await invitationService.getInvite(invitationId).catch(() => {
          toast.error(t('toast.error.general.inviteNotFound'));
          navigate('/');
        }));

      if (!inv) return;
      setInvite(inv);
      const dataRequest = Array.from(new Set([...inv.dataRequest, ProfileDataType.EMAIL]));

      setProfileFields(sortProfileFields(dataRequest.map((dr) => createEmptyField(dr))));
    };

    setFields();
  }, []);

  const validateFields = (): boolean => {
    if (!alias || !alias.split('+')[0] || !alias.split('+')[1]) {
      toast.error(t('toast.error.field.name'));
      return false;
    }
    if (!terms) {
      toast.error(t('toast.error.general.terms'));
      return false;
    }
    if (isEmptyField(profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!)) {
      toast.error(t('toast.error.field.email'));
      return false;
    }
    return isValidProfileFields(profileFields);
  };

  const handleSave = async () => {
    setValidate(true);
    if (!validateFields()) return;
    let data = profileFields.filter((x) => !isEmptyField(x));
    const email = profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!.email!;

    const emailRequested = invite?.dataRequest.find((x) => x === ProfileDataType.EMAIL);
    if (!emailRequested) data = data.filter((x) => x.dataType !== ProfileDataType.EMAIL);

    const exists = await profileService.checkEmailExists(email);
    if (exists) {
      setAccountExists(true);
      setAccountExistsModal(true);
      return;
    }

    const login = await connectionService.instantRegister(
      email,
      alias,
      invite?.provider.profile.alias!,
      InviteType.USER,
      invitationId!,
      data,
      Language.NL,
    );

    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.invite',
      translationOptions: {
        requesterName: requesterName || '',
      },
      requesterId: invite?.providerId,
      requesterAlias: invite?.provider.profile.alias,
    };
    navigate('/success', { state });
    toast.success(t('toast.success.connectionCreated'));
    setTimeout(login, 1);
  };

  const handleLogin = async () => {
    const state: LoginPageState = {
      email: emailField?.email || profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!.email!,
      invitationId: invitationId!,
      inviteType: InviteType.USER,
      invite: invite!,
    };
    navigate('/login', { state });
  };

  const requesterName = invite?.provider.profile.alias.split('+')[0];
  const dataRequestEmpty = invite?.dataRequest.length === 0;

  const setProfileFieldByType = (field: ProfileField) => {
    setProfileFields(profileFields.map((f: ProfileField): ProfileField => (f.dataType === field.dataType ? field : f)));
  };

  return (
    <>
      <ResponsePageWrapper handleLogin={handleLogin}>
        <ResponsePageWrapper.Header>
          {dataRequestEmpty ? (
            <BusinessCardHeader
              requesterAlias={invite?.provider.profile.alias!}
              providedTypes={invite?.dataProvidedTypes!}
              profilePicture={invite?.profilePicture!}
            />
          ) : (
            <InviteHeader requesterName={requesterName || ''} />
          )}
        </ResponsePageWrapper.Header>

        <div className="flex flex-col">
          <h2 className="text-lg font-medium">
            {dataRequestEmpty ? t('page.shared.receiveData') : t('page.shared.requestedData')}
          </h2>
          <p className="text-sm">
            {dataRequestEmpty
              ? t('page.invite.businessCardMessage', { name: requesterName })
              : // eslint-disable-next-line prefer-template
                t('page.invite.message', {
                  name: requesterName,
                  dataRequest: joinWithAnd(invite?.dataRequest.map(profileDataTypeToString) || []),
                }) +
                ' ' +
                t('page.invite.nameEmailMandatory')}
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-4">
          <NameInput alias={alias} setAlias={setAlias} mandatory={!dataRequestEmpty} validate={validate} />

          {emailField && (
            <EmailInput
              field={emailField!}
              emailCheck
              validate={validate}
              setAccountExists={setAccountExists}
              setField={setProfileFieldByType}
              mandatory={!dataRequestEmpty}
            />
          )}
          {accountExists ? (
            <span className="text-sm text-error">
              {t('page.shared.emailInUse')}{' '}
              <strong className="cursor-pointer underline" onClick={handleLogin}>
                {t('page.shared.logInHere')}
              </strong>
              .
            </span>
          ) : (
            ''
          )}

          {profileFields.map((field) => {
            return field.dataType === ProfileDataType.EMAIL ? (
              <></>
            ) : (
              <div key={field.dataType} className={field.dataType === ProfileDataType.BIRTHDATE ? 'w-1/2' : ''}>
                <ProfileFieldForm field={field} canBeEmpty validate={validate} setField={setProfileFieldByType} />
              </div>
            );
          })}
        </div>
        <TermsAndConditions
          checked={terms}
          setChecked={setTerms}
          className="mt-4"
          name={invite?.dataRequest.length === 0 ? '' : requesterName}
        />
        <div className="mt-4 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button variant="primary" className="px-[32px]" onClick={handleSave}>
            {t('page.shared.acceptInvite')}
          </Button>
        </div>
      </ResponsePageWrapper>
      {emailField?.email && (
        <AccountExistsModal
          open={accountExistsModal}
          setOpen={setAccountExistsModal}
          request={{
            email: emailField?.email,
            invitationId: invitationId ?? undefined,
            inviteType: InviteType.USER,
          }}
        />
      )}
    </>
  );
}
