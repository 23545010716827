import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDeleteParams from '../../../hooks/useDeleteParams';
import { eventService } from '../../../services';
import ResponsePageWrapper from '../../../components/layouts/ResponsePageWrapper';
import useAuth from '../../../hooks/useAuth';
import Button from '../../../components/misc/Button';
import { LoginPageState, SuccessPageState } from '../../../types/navigation';
import { DateOptionAnswer, Event, EventParticipationAnswer } from '../../../types/Events';
import { getPollResponses, userHasGivenAvailability } from '../../../utils/eventUtils';
import { getUserId } from '../../../services/httpService';
import CustomFieldsForm from '../../../components/forms/CustomFieldsForm';
import { CustomFieldResponses, InviteType } from '../../../types/invites';
import { formatCustomFieldResponsesFromCustomFields } from '../../../utils/formatUtils';
import EventOverview from '../../../components/overviews/EventOverview';
import EventSelectDateOption from '../../../components/forms/events/EventSelectDateOption';
import useMagicLogin from '../../../hooks/useMagicLogin';

function DatePickerRespondPage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();
  useDeleteParams('link');
  const eventId = params.get('id');

  const [event, setEvent] = useState<Event>();
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>([]);
  const [hasGivenAvailability, setHasGivenAvailability] = useState<boolean>(false);
  const [dateOptionsUpdate, setDateOptionsUpdate] = useState<
    Record<number, { answer: EventParticipationAnswer; description?: string }>
  >({});

  if (useMagicLogin()) {
    return <></>;
  }

  useEffect(() => {
    if (!eventId) {
      toast.error(t('toast.error.general.invalidInvite'));
      setTimeout(() => navigate('/'), 3000);
      return;
    }

    if (!auth) return;

    eventService
      .getEvent(eventId)
      .then((e) => setEvent(e))
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        setTimeout(() => navigate('/'), 3000);
      });
  }, [JSON.stringify(auth)]);

  useEffect(() => {
    if (!auth) return;

    try {
      const userId = getUserId();
      if (!userId || !event) return;

      if (!event.isDatePicker) {
        navigate(`/event?id=${eventId}`);
      }

      const responses = getPollResponses(userId, event!.customFields);
      setCustomFieldResponses(formatCustomFieldResponsesFromCustomFields(event!.customFields, responses));
      setHasGivenAvailability(userHasGivenAvailability(event, userId));
    } catch (error) {
      console.error(error);
    }
  }, [event]);

  const validate = () => {
    if (!hasGivenAvailability && Object.keys(dateOptionsUpdate).length !== event!.dateOptions.length) {
      toast.error(t('page.events.invite.datePickers.missingOptions'));
      return false;
    }

    if (event!.customFields.length > 0) {
      return event!.customFields.every((field) => {
        if (field.mandatory && !customFieldResponses[field.customFieldId]) {
          toast.error(t('toast.error.field.mandatoryStar'));
          return false;
        }
        return true;
      });
    }

    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!validate()) return;

    const dateOptions = Object.entries(dateOptionsUpdate).map(([dateOptionId, { answer, description }]) => ({
      dateOptionId: parseInt(dateOptionId, 10),
      answer,
      description,
    }));

    await eventService.respondToEventDateOptionBulk(eventId!, dateOptions);

    if (event!.customFields.length > 0) {
      await eventService.respondToEventPollBulk(eventId!, customFieldResponses);
    }

    const state: SuccessPageState = { translationKey: 'page.events.invite.message.success' };
    navigate('/success', { state });
  };

  const handleLogin = async () => {
    const state: LoginPageState = { inviteType: InviteType.DATE_PICKER, eventId: eventId!, hideBackButton: true };
    navigate('/login', { state });
  };

  if (!auth)
    return (
      <ResponsePageWrapper handleLogin={handleLogin}>
        <div className="flex flex-col gap-4">
          <h1 className="font-serif text-3xl font-semibold">{t('page.events.invite.message.logIn')}</h1>
          <Button onClick={handleLogin} className="self-start" variant="primary">
            {t('page.shared.logInHere')}
          </Button>
        </div>
      </ResponsePageWrapper>
    );

  if (!event || !eventId) return <></>;

  const { deadline } = event;

  const deadlineIsPassed = deadline ? new Date() > new Date(deadline) : false;

  return (
    <ResponsePageWrapper handleLogin={handleLogin}>
      <EventOverview event={event}>
        {!deadlineIsPassed && (
          <div className="flex flex-col gap-4">
            <div className="font-serif text-lg">{t('page.events.invite.datePickers.giveAvailability')}</div>

            {event.dateOptions.map((dateOption) => {
              const userId = getUserId();
              return dateOption.answers
                .filter((answer: DateOptionAnswer) => answer.id === userId)
                .map((dateOptionAnswer) => (
                  <EventSelectDateOption
                    key={dateOption.id}
                    dateOption={dateOption}
                    eventId={+eventId}
                    dateOptionAnswer={dateOptionsUpdate[dateOption.id] || dateOptionAnswer}
                    onSave={(_, dateOptionId, answer, description) => {
                      const update = { [dateOptionId]: { answer, description } };
                      setDateOptionsUpdate((prev) => ({ ...prev, ...update }));
                    }}
                  />
                ));
            })}

            {event.customFields.length > 0 && (
              <>
                <div className="font-serif text-lg">{t('page.events.invite.polls.title')}</div>
                <CustomFieldsForm
                  fields={event.customFields}
                  response={customFieldResponses}
                  setResponse={setCustomFieldResponses}
                />
              </>
            )}
          </div>
        )}
      </EventOverview>

      {!deadlineIsPassed && (
        <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button className="underline" onClick={() => navigate('/')}>
            {t('general.cancel')}
          </Button>
          <Button variant="primary" className="px-[32px]" onClick={handleSubmit}>
            {t('general.save')}
          </Button>
        </div>
      )}
    </ResponsePageWrapper>
  );
}

export default DatePickerRespondPage;
