import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useProfileFields from '../../../hooks/useProfileFields';
import { ProfileDataType, ProfileField } from '../../../types/profile';
import { DataRequest, InviteType } from '../../../types/invites';
import { connectionService, invitationService } from '../../../services';
import { InvalidAccountResponse } from '../../../types/responses';
import UserDataForm from '../../../components/forms/userData/UserDataForm';
import ResponsePageWrapper from '../../../components/layouts/ResponsePageWrapper';
import InvalidAuthHeader from '../../../components/headers/InvalidAuthHeader';
import InvalidAuthBody from '../../../components/misc/InvalidAuthBody';
import useAuth from '../../../hooks/useAuth';
import WarningModal from '../../../components/modals/WarningModal';
import DataRequestHeader from '../../../components/headers/DataRequestHeader';
import { SuccessPageState } from '../../../types/navigation';
import NoDataSharedModal from '../../../components/modals/NoDataSharedModal';
import Button from '../../../components/misc/Button';
import useMagicLogin from '../../../hooks/useMagicLogin';

export default function DataRequestPage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const profileFields = useProfileFields();
  const auth = useAuth();
  const location = useLocation();

  if (useMagicLogin()) {
    return <></>;
  }

  const invitationId = params.get('invitationId');

  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [invalidAccountInfo, setInvalidAccountInfo] = useState<InvalidAccountResponse>();
  const [dataRequest, setDataRequest] = useState<DataRequest>(location.state?.request ?? undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [noDataSharedModalOpen, setNoDataSharedModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!invitationId && !location.state?.request) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
      return;
    }
    if (location.state?.request) return;

    invitationService
      .getDataRequest(invitationId!)
      .then((datReq): void => {
        setDataRequest(datReq);
        setInvalidAccountInfo(undefined);
      })
      .catch((err: AxiosError<InvalidAccountResponse>): void => {
        if (err.response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          setInvalidAccountInfo(err.response.data);
        } else {
          toast.error(t('toast.error.general.inviteNotFound'));
          navigate('/');
        }
      });
  }, [JSON.stringify(auth)]);

  const fetchedShared = useRef<boolean>(false);
  useEffect((): void => {
    if (!dataRequest || profileFields.length === 0 || fetchedShared.current) return;

    connectionService
      .getSharedDataWithUser(dataRequest?.senderId.toString())
      .then((data) => {
        setSelectedData(data.filter((f) => dataRequest?.requestedProps.includes(f.dataType)));
      })
      .catch((): void => {});
    fetchedShared.current = true;
  }, [dataRequest, profileFields]);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setNoDataSharedModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = async (): Promise<void> => {
    if (!dataRequest) return;
    await connectionService.acceptDataRequest(
      { token: invitationId ?? undefined, dataRequestId: dataRequest.dataRequestId },
      selectedData.map((f) => f.id!),
    );

    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.dataRequest.accepted',
      translationOptions: {
        requesterName: dataRequest?.requesterAlias.split('+')[0],
      },
      requesterId: dataRequest?.senderId!,
      requesterAlias: dataRequest?.requesterAlias,
    };

    navigate('/success', { state });
  };

  const handleDecline = async (): Promise<void> => {
    if (!dataRequest) return;
    await connectionService.declineDataRequest(dataRequest?.dataRequestId!);
    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.dataRequest.rejected',
      translationOptions: {
        requesterName: dataRequest?.requesterAlias.split('+')[0],
      },
    };
    navigate('/success', { state });
  };

  const requesterName = dataRequest?.requesterAlias.split('+')[0] || '';

  if (invalidAccountInfo)
    return (
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InvalidAuthHeader type={InviteType.DATA_REQUEST} />
        </ResponsePageWrapper.Header>
        <InvalidAuthBody info={invalidAccountInfo} invitationId={invitationId!} inviteType={InviteType.DATA_REQUEST} />
      </ResponsePageWrapper>
    );

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <DataRequestHeader requesterName={requesterName || ''} />
        </ResponsePageWrapper.Header>
        <div className="flex h-full flex-1 flex-col gap-4">
          <div className="flex flex-col">
            <h2 className="text-lg font-medium">{t('page.dataRequest.title', { name: requesterName || '' })}</h2>
            <p className="text-sm">{t('page.dataRequest.description')}</p>
          </div>

          <UserDataForm
            selectedData={selectedData || []}
            setSelectedData={setSelectedData}
            onlyBirthDate
            nonRequestedFields={
              Object.keys(ProfileDataType).filter(
                (f) => !dataRequest?.requestedProps.includes(f as ProfileDataType),
              ) as ProfileDataType[]
            }
            hasSelectAll
          />
          <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
            <Button onClick={handleDecline} className="underline">
              {t('general.decline')}
            </Button>
            <Button
              variant="primary"
              className="px-[32px]"
              onClick={
                !dataRequest?.requestedProps.every((f) => selectedData.map((d) => d.dataType).includes(f))
                  ? (): void => setModalOpen(true)
                  : handleComplete
              }>
              {t('page.shared.shareData')}
            </Button>
          </div>
        </div>
      </ResponsePageWrapper>
      <WarningModal
        open={modalOpen}
        setOpen={setModalOpen}
        title={t('modal.dataRequestWarning.title')}
        description={t('modal.dataRequestWarning.description')}
        buttonText={t('general.continue')}
        onSubmit={handleComplete}
      />
      <NoDataSharedModal
        open={noDataSharedModalOpen}
        setOpen={setNoDataSharedModalOpen}
        requesterName={dataRequest?.requesterAlias.split('+')[0] || ''}
        handleComplete={handleSubmit}
      />
    </>
  );
}
