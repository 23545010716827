import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { authenticationService, connectionService, invitationService, profileService } from '../../services';
import {
  BusinessInvite,
  BusinessVersion,
  CustomFieldResponses,
  CustomFieldType,
  InviteType,
} from '../../types/invites';
import { ProfileDataType, ProfileField } from '../../types/profile';
import { createEmptyCustomFieldResponses, createEmptyField } from '../../utils/formatUtils';
import { sortProfileFields } from '../../utils/sortUtils';
import { checkMandatoryFields, isEmptyField, isValidProfileFields } from '../../utils/validationUtils';
import ProfileFieldForm from '../../components/forms/profile/ProfileFieldForm';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import { LoginPageState, SuccessPageState } from '../../types/navigation';
import NameInput from '../../components/forms/profile/NameInput';
import TermsAndConditions from '../../components/misc/TermsAndConditions';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../components/headers/InviteHeader';
import EmailInput from '../../components/forms/profile/EmailInput';
import CommunicationNameInput from '../../components/forms/profile/CommunicationNameInput';
import Button from '../../components/misc/Button';
import { UserAccountCreationChannel, UserAccountCreationEvent } from '../../analytics/models';
import { SharedBusinessData } from '../../types/misc';
import AccountExistsModal from '../../components/modals/AccountExistsModal';
import useMagicLogin from '../../hooks/useMagicLogin';

export default function UnauthenticatedBusinessInvitePage(): JSX.Element {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const invitationId = params.get('invitationId');
  const type = params.get('type') as BusinessVersion | null;
  const link = params.get('link');

  if (useMagicLogin()) {
    return <></>;
  }

  const [invite, setInvite] = useState<BusinessInvite>();
  const [alias, setAlias] = useState<string>('');
  const [profileFields, setProfileFields] = useState<ProfileField[]>([]);
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>(
    createEmptyCustomFieldResponses([]),
  );
  const [accountExists, setAccountExists] = useState<boolean>(false);
  const [accountExistsModal, setAccountExistsModal] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);

  const emailField = profileFields.find((x) => x.dataType === ProfileDataType.EMAIL);

  useEffect(() => {
    if (!invitationId || !type) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
    }
  }, []);

  useLayoutEffect(() => {
    if (!invitationId || link || !type) return;

    const setFields = async (): Promise<void> => {
      const inv =
        (location.state?.businessInvite as BusinessInvite) ||
        (await invitationService.getBusinessInvite(invitationId, type).catch(() => {
          toast.error(t('toast.error.general.inviteNotFound'));
          navigate('/');
        }));

      if (!inv) return;
      setInvite(inv);
      const { mandatoryFields, optionalFields, customFields } = inv;
      const dataRequest = Array.from(new Set([...mandatoryFields, ...optionalFields, ProfileDataType.EMAIL]));
      if (!inv.shareWithAdmins && inv.connectedMembers) {
        dataRequest.push(ProfileDataType.ADDRESS);
        dataRequest.push(ProfileDataType.PHONENUMBER);
        dataRequest.push(ProfileDataType.BIRTHDATE);
      }
      setProfileFields(sortProfileFields(dataRequest.map((dr) => createEmptyField(dr))));
      setCustomFieldResponses(createEmptyCustomFieldResponses(customFields));
    };

    setFields();
  }, []);

  const validateFields = (): boolean => {
    if (!alias || !alias.split('+')[0] || !alias.split('+')[1]) {
      toast.error(t('toast.error.field.name'));
      return false;
    }
    if (!terms) {
      toast.error(t('toast.error.general.terms'));
      return false;
    }
    if (isEmptyField(profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!)) {
      toast.error(t('toast.error.field.email'));
      return false;
    }
    return isValidProfileFields(profileFields);
  };

  const handleSave = async () => {
    if (!invite || !invitationId) return;

    setValidate(true);
    if (!validateFields()) return;
    if (!checkMandatoryFields(invite!, profileFields, customFieldResponses)) return;
    const email = profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!.email!;

    const exists = await profileService.checkEmailExists(email);
    if (exists) {
      setAccountExists(true);
      setAccountExistsModal(true);
      return;
    }

    const event: UserAccountCreationEvent = {
      channel: UserAccountCreationChannel[UserAccountCreationChannel.DEFAULT],
      details: undefined,
    };

    const login = await authenticationService.register(alias, email, event);
    const properties = await profileService.addFields(
      profileFields.filter((x) => !isEmptyField(x) && x.dataType !== ProfileDataType.EMAIL),
    );

    const args: SharedBusinessData = {
      propertyIds: invite.shareWithAdmins
        ? properties
            .filter((f) => f.dataType !== ProfileDataType.EMAIL || !invite.nonRequestedFields.includes(f.dataType))
            .map((x) => x.id!)
        : [],
      customFieldResponses,
      dataForMembers: [],
    };

    await connectionService.createBusinessConnection(invitationId, args, invite);

    if (!invite.shareWithAdmins && invite.connectedMembers) {
      await connectionService.updateDataSharedWithCommunityMembers(
        +invite.requesterId,
        properties.map((d) => +d.id!),
      );
    }

    const state: SuccessPageState = {
      translationKey: `page.common.success.message.${
        invite?.version === BusinessVersion.COMMUNITY ? 'businessCommunityInvite' : 'businessInvite'
      }`,
      translationOptions: {
        businessName: invite?.requesterAlias.replace('+', ' '),
      },
      ...(invite?.version === BusinessVersion.COMMUNITY
        ? { businessInvite: invite!, inviteType: InviteType.BUSINESS }
        : {
            requesterId: invite?.requesterId,
            requesterAlias: invite?.requesterAlias,
            inviteType: InviteType.MOMENTS,
          }),
    };
    toast.success(t('toast.success.connectionCreated'));
    if (invite.connectedMembers && invite.shareWithAdmins)
      navigate('/community/members', { state: { invite, success: state, replace: true } });
    else navigate('/success', { state });
    setTimeout(login, 1);
  };

  const handleLogin = async () => {
    const state: LoginPageState = {
      email: profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!.email!,
      invitationId: invitationId!,
      businessInvite: invite!,
      inviteType: invite?.version === BusinessVersion.COMMUNITY ? InviteType.BUSINESS : InviteType.MOMENTS,
    };
    navigate('/login', { state });
  };

  const setProfileFieldByType = (field: ProfileField) => {
    if (field.dataType === ProfileDataType.SOCIAL) {
      setProfileFields(
        profileFields.map(
          (f: ProfileField): ProfileField => (f.social?.socialType === field.social?.socialType ? field : f),
        ),
      );
    } else
      setProfileFields(
        profileFields.map((f: ProfileField): ProfileField => (f.dataType === field.dataType ? field : f)),
      );
  };

  const requesterName = invite?.requesterAlias.split('+')[0] || '';
  const communicationName = invite?.customFields.find((f) => f.type === CustomFieldType.COMMUNICATION_NAME);
  return (
    <>
      <ResponsePageWrapper handleLogin={handleLogin}>
        <ResponsePageWrapper.Header>
          <InviteHeader
            requesterName={requesterName || ''}
            message={
              invite?.shareWithAdmins
                ? invite?.message!
                : t('page.businessInvite.noShareWithAdmins.title', { communityName: invite?.requesterAlias })
            }
            submessage={invite?.shareWithAdmins ? undefined : t('page.businessInvite.noShareWithAdmins.subtitle')}
          />
        </ResponsePageWrapper.Header>

        <div className="flex flex-col">
          <h2 className="text-lg font-medium">
            {!invite?.shareWithAdmins && invite?.connectedMembers
              ? t('page.businessInvite.noShareWithAdmins.description', { communityName: invite?.requesterAlias })
              : t('page.shared.requestedData')}
          </h2>
          <p className="text-sm">
            {!invite?.shareWithAdmins && invite?.connectedMembers
              ? t('page.businessInvite.noShareWithAdmins.mandatory')
              : t('page.businessInvite.message', { name: requesterName })}
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-4">
          <NameInput alias={alias} setAlias={setAlias} mandatory validate={validate} />

          {communicationName && (
            <CommunicationNameInput
              name={customFieldResponses[communicationName.customFieldId]}
              validate={validate}
              setName={(name) =>
                setCustomFieldResponses({
                  ...customFieldResponses,
                  [communicationName.customFieldId]: name,
                })
              }
              mandatory={communicationName?.mandatory}
            />
          )}

          {emailField && (
            <EmailInput
              field={emailField!}
              emailCheck
              validate={validate}
              mandatory
              setAccountExists={setAccountExists}
              setField={setProfileFieldByType}
            />
          )}
          {accountExists ? (
            <span className="text-sm text-error">
              {t('page.shared.emailInUse')}{' '}
              <strong className="cursor-pointer underline" onClick={handleLogin}>
                {t('page.shared.logInHere')}
              </strong>
              .
            </span>
          ) : (
            ''
          )}

          {profileFields.map((field) => {
            return field.dataType === ProfileDataType.EMAIL ? (
              <></>
            ) : (
              <ProfileFieldForm
                field={field}
                canBeEmpty={invite?.optionalFields.includes(field.dataType)}
                validate={validate}
                mandatory={invite?.mandatoryFields.includes(field.dataType)}
                setField={setProfileFieldByType}
              />
            );
          })}
          <CustomFieldsForm
            fields={invite?.customFields || []}
            response={customFieldResponses}
            setResponse={setCustomFieldResponses}
          />
        </div>
        <div className="mt-2">
          <TermsAndConditions checked={terms} setChecked={setTerms} name={requesterName} />
        </div>
        <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button variant="primary" className="px-[32px]" onClick={handleSave}>
            {t('page.shared.acceptInvite')}
          </Button>
        </div>
      </ResponsePageWrapper>
      {emailField?.email && (
        <AccountExistsModal
          open={accountExistsModal}
          setOpen={setAccountExistsModal}
          request={{
            email: emailField?.email,
            invitationId: invitationId ?? undefined,
            inviteType: invite?.version === BusinessVersion.COMMUNITY ? InviteType.BUSINESS : InviteType.MOMENTS,
          }}
        />
      )}
    </>
  );
}
