import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RiArrowRightFill } from 'react-icons/ri';

import Button from './Button';
import { authenticationService } from '../../services';
import { detectMobileOS } from '../../utils/userAgentUtils';
import { MobileOS } from '../../types/misc';

export default function LoginButton(props: { redirectTo?: string }): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const link = params.get('link');
  const { redirectTo } = props;
  const mobileOS = detectMobileOS();

  const loginMagic = useCallback(() => {
    authenticationService
      .loginMagic(link || '')
      .catch(() => {
        toast.error(t('toast.error.login'));
      })
      .finally(() => {
        if (redirectTo) {
          navigate(redirectTo);
        }
      });
  }, [authenticationService, link, redirectTo]);

  if (mobileOS === MobileOS.UNKNOWN) {
    return (
      <Button variant="primary-outline" onClick={() => loginMagic()}>
        <RiArrowRightFill className="icon mr-2 h-5 w-5" />
        <span className="inline whitespace-nowrap">{t('general.continue')}</span>
      </Button>
    );
  }

  return <p className="text-base">{t('page.magicLogin.mobileContent')}</p>;
}
